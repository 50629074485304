import kiiry_svg from '../../../assets/images/logo.png';
// import Logo from '../../../assets/images/logoMaedeDeus.png';
import { Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { Form as RHForm, TextInput, SelectInput, FormInput, DateInput } from '@/components';
import FileUploaderInput from '@/components/Form/FileUploaderInput';
import { FC, useEffect, useState } from 'react';
import useEmployee from './hooks/useEmployee';
import { useModal } from '@/hooks';
import { Errors } from '@/types';
import ModalMessage from '@/components/Modal/modalMessage';
import { ConfirmData } from '@/types/ConfirmData';
import { t } from 'i18next';
interface Props {
	employee?: ConfirmData;
	token?: string;
	tokenType: string;
	update?: boolean;
}

const Form: FC<Props> = ({ employee, token, tokenType, update = false }) => {
	const {
		useUf,
		errors,
		setErrors,
		// ConnectionType,
		// selectedConnection,
		schema,
		defaultValues,
		setEmployeeData,
		handleValidSubmit,
		startDate,
		setStartDate,
		waitingApi,
		mapToDefaultValues,
		schemaForCreate,
		updateSuccess,
		MaritalStatus,
		selectedMaritalStatus,
	} = useEmployee(() => openModalWithClass('success'));
	const [reload, setReload] = useState(true);
	const { isOpen, className, openModalWithClass, closeModal } = useModal();
	const {
		UfOptions,
		selectedUF,
		onChangeUF,
		citiesOptions,
		selectedCity,
		onChangeCity,
		loadingCities,
	} = useUf;
	const [hasErrors, setHasErrors] = useState<boolean>(false);

	const handleFieldChange = (fieldName: keyof Errors) => {
		if (errors && errors[fieldName]) {
			const newApiErrors = { ...errors };
			delete newApiErrors[fieldName];
			setErrors(newApiErrors);
		}
	};

	useEffect(() => {
		if (errors !== undefined && Object.keys(errors).length !== 0) {
			const invalidInputs = document.querySelectorAll('.is-invalid');
			if (invalidInputs.length > 0) {
				invalidInputs[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
			setTimeout(() => {
				setHasErrors(true);
				openModalWithClass('danger');
			}, 1000);
		} else {
			setHasErrors(false);
		}
	}, [errors]);

	useEffect(() => {
		setEmployeeData(employee);
		if (update) {
			if (employee?.address?.state) onChangeUF(employee?.address?.state);
			if (employee?.address?.city) onChangeCity(employee?.address?.city);
			mapToDefaultValues(employee);
		}
	}, [employee]);

	const handleChangeDate = (date: Date) => {
		setStartDate(date);
	};

	useEffect(() => {
		setReload(false);
	}, defaultValues);

	return (
		<>
			{!updateSuccess ? (
				<div className="preenchimento-content">
					<div className="prenchimento-header">
						{employee?.logo ? (
							<img className="logo-preenchimento" src={employee.logo} />
						) : (
							<img className="logo-preenchimento" src={kiiry_svg} />
						)}
						<span className="logo-lg">
							<img src={kiiry_svg} />
						</span>
					</div>
					<h4>
						<span style={{fontSize: '1.2rem'}}>{employee?.name ?? 'Olá'}</span>
						, favor informar os dados da conta
					</h4>
					<div>
						{!reload && (
							<RHForm
								onSubmit={(e) => {
									handleValidSubmit(e, token ?? '', update);
								}}
								schema={update ? schema : schemaForCreate}
								defaultValues={defaultValues}
							>
								<Card>
									<Card.Body>
										<Card className="aviso-preenchimento">
											<h4>
												Tenha em mãos a conta de energia a ser solicitada o benefício
											</h4>
										</Card>
										<Row>
											<Col lg={6}>
												<TextInput
													type="text"
													name="employee_bill.name"
													label="Nome Completo"
													placeholder="Informe o nome do titular da conta"
													containerClass={'mb-3'}
													errors={errors}
													onChange={() =>
														handleFieldChange('employee_bill.name')
													}
												/>
											</Col>
											<Col lg={3}>
												<FormInput
													name="employee_bill.cpf"
													label="CPF"
													type="cpf"
													placeholder="000.000.000-00"
													containerClass={'mb-3'}
													errors={errors}
													onChangeCustom={() =>
														handleFieldChange('employee_bill.cpf')
													}
												/>
											</Col>
											<Col lg={3}>
												<TextInput
													type="text"
													name="employee_bill.identidade"
													label="Cédula de Identidade"
													placeholder="0000000000"
													containerClass={'mb-3'}
													errors={errors}
													onChange={() =>
														handleFieldChange('employee_bill.identidade')
													}
												/>
											</Col>
											<Col lg={3}>
												<DateInput
													label="Data de Nascimento"
													name="employee_bill.birthdate"
													className="form-control"
													value={startDate}
													onChange={(date) => handleChangeDate(date)}
													containerClass={'mb-3'}
													errors={errors}
													type={'date'}
												/>
											</Col>
											<Col lg={3}>
												<FormInput
													name="employee_bill.phone_number"
													label="Telefone/Whatsapp"
													placeholder="(99)99999-9999"
													type="phone_number"
													containerClass={'mb-3'}
													errors={errors}
													onChangeCustom={() =>
														handleFieldChange(
															'employee_bill.phone_number'
														)
													}
												/>
											</Col>
											<Col lg={6}>
												<TextInput
													type="email"
													name="employee_bill.email"
													label="E-mail de contato"
													placeholder="E-mail"
													containerClass={'mb-3'}
													errors={errors}
													onChange={() =>
														handleFieldChange('employee_bill.email')
													}
												/>
											</Col>
											<Col lg={4}>
												<TextInput
													name="employee_bill.profession"
													label={'Profissão'}
													type="text"
													errors={errors}
													containerClass="mb-3"
												/>
											</Col>
											<Col lg={4}>
												<SelectInput
													name="employee_bill.marital_status"
													label="Estado Civil"
													className="react-select"
													containerClass={'mb-3'}
													selectedValue={selectedMaritalStatus}
												>
													<option
														value=""
														defaultChecked={selectedMaritalStatus == ''}
													>
														Selecione
													</option>
													{MaritalStatus.map((marital) => (
														<option
															key={marital.id}
															value={marital.value}
														>
															{marital.label}
														</option>
													))}
												</SelectInput>
											</Col>
											<Col lg={6}>
												<SelectInput
													label="Estado"
													name="employee_bill.address.state"
													className="react-select"
													containerClass="mb-3"
													onChangeCustom={onChangeUF}
													selectedValue={selectedUF}
													errors={errors}
												>
													<option
														value=""
														defaultChecked={selectedUF == ''}
													>
														{loadingCities
															? 'Carregando estados...'
															: 'Selecione um estado'}
													</option>
													{UfOptions.map((uf) => (
														<option
															key={uf.id}
															value={uf.value}
															defaultChecked={
																selectedUF == uf.value
															}
														>
															{uf.label}
														</option>
													))}
												</SelectInput>
											</Col>
											<Col lg={6}>
												<SelectInput
													label="Cidade"
													name="employee_bill.address.city"
													className="react-select"
													containerClass="mb-3"
													onChangeCustom={onChangeCity}
													selectedValue={selectedCity}
													errors={errors}
												>
													<option
														value=""
														defaultChecked={selectedCity == ''}
													>
														{loadingCities
															? 'Carregando cidades...'
															: 'Selecione uma cidade'}
													</option>
													{citiesOptions.map((city, key) => (
														<option
															key={key}
															value={city}
															defaultChecked={
																selectedCity == city
															}
														>
															{city}
														</option>
													))}
												</SelectInput>
											</Col>
											<Col lg={5}>
												<TextInput
													name="employee_bill.address.street"
													label="Rua"
													placeholder="Digite a Rua"
													errors={errors}
													type="text"
													containerClass={'mb-3'}
												/>
											</Col>
											<Col lg={5}>
												<TextInput
													name="employee_bill.address.neighborhood"
													label="Bairro"
													errors={errors}
													placeholder="Digite o Bairro"
													type="text"
													containerClass={'mb-3'}
												/>
											</Col>
											<Col lg={2}>
												<TextInput
													name="employee_bill.address.number"
													label="Número"
													placeholder="Digite o Número"
													errors={errors}
													type="text"
													containerClass={'mb-3'}
												/>
											</Col>
											<Col lg={6}>
												<TextInput
													name="employee_bill.address.complement"
													label="Complemento"
													placeholder="Digite o Complemento"
													errors={errors}
													type="text"
													containerClass={'mb-3'}
												/>
											</Col>
											<Col lg={3}>
												<FormInput
													name="employee_bill.address.cep"
													label="CEP"
													placeholder="Digite o CEP"
													type="cep"
													containerClass={'mb-3'}
													errors={errors}
												/>
											</Col>
										</Row>
										<Row>
											<Col lg={6}>
												<FileUploaderInput
													name="employee_bill.file_document"
													type="file"
													containerClass="mb-3 mt-3 mt-xl-0"
													label="*Upload da Última Conta de Energia"
													helpText="Enviar PDF ou Imagem de até 20 MB."
													maxSize={20}
													maxFiles={1}
													errors={errors}
												/>
											</Col>
										</Row>
									</Card.Body>
								</Card>
								{/* <Card>
									<Card.Header>
										<h4>Informar os dados de instalação e consumo:</h4>
									</Card.Header>
									<Card.Body>
										<Row>
											<Col lg={3}>
												<TextInput
													type="number"
													name="employee_bill.number"
													label="Número da Instalação"
													placeholder="3000000000"
													containerClass={'mb-3'}
													errors={errors}
												/>
											</Col>
											<Col lg={6}>
												<TextInput
													type="number"
													name="employee_bill.quantity"
													label="Quantidade de Energia a ser Contratado [Média dos Ultimos Meses]"
													placeholder="Consumo kWh/mês"
													containerClass={'mb-3'}
													errors={errors}
												/>
											</Col>
											<Col lg={3}>
												<SelectInput
													name="employee_bill.connection_type"
													label="Tipo de Ligação"
													className="react-select"
													containerClass={'mb-3'}
													selectedValue={selectedConnection}
													errors={errors}
												>
													<option
														value=""
														defaultChecked={selectedConnection == ''}
													>
														Selecione um tipo de conexão
													</option>
													{ConnectionType.map((connection) => (
														<option
															key={connection.id}
															value={connection.value}
														>
															{connection.label}
														</option>
													))}
												</SelectInput>
											</Col>
											<Col lg={6}>
												<FileUploaderInput
													name="employee_bill.file_document"
													type="file"
													containerClass="mb-3 mt-3 mt-xl-0"
													label="*Upload da Última Conta de Energia"
													helpText="Enviar PDF ou Imagem de até 20 MB."
													maxSize={20}
													maxFiles={1}
													errors={errors}
												/>
											</Col>
										</Row>
									</Card.Body>
								</Card> */}
								<div className="d-flex justify-content-between">
									<div className="faq">
										<a
											href="https://www.kiiry.com/duvidas"
											target="_blank"
											rel="noopener noreferrer"
										>
											Perguntas frequentes
										</a>
									</div>
									<div>
										<Button
											type="submit"
											className="m-2"
											variant="btnColor"
											disabled={hasErrors || waitingApi}
										>
											{waitingApi ? (
												<Spinner
													className="text-primary m-2"
													color="primary"
													size={'sm'}
												/>
											) : (
												<>Confirmar Envio</>
											)}
										</Button>
									</div>
								</div>
							</RHForm>
						)}
					</div>
				</div>
			) : (
				<div className="content-use-terms">
					<div className="img-principal">
						<img className="logo" src={kiiry_svg} alt="logo" />
					</div>
					<div className="formulario-use-terms">
						<div className="msg_text">
							<h3>Dados Corrigidos com sucesso</h3>
							<p>As alterações foram salvas com sucesso!</p>
						</div>
					</div>
				</div>
			)}
			<ModalMessage
				isOpen={isOpen}
				toggleModal={
					className == 'success'
						? () => (window.location.href = 'https://www.kiiry.com/')
						: () => {
								closeModal();
								setErrors({});
							}
				}
				className={className}
				title={t('pages.admin.adesao.form.modal.success')}
				errors={errors}
				customButtons={
					className == 'success' && (
						<>
							<Button variant={'btnColor'} onClick={() => window.location.reload()}>
								Solicitar Para Outro Endereço
							</Button>
							<Button
								variant={'btnColor'}
								onClick={() => (window.location.href = 'https://www.kiiry.com/')}
							>
								Ir para a página da KIIRY
							</Button>
						</>
					)
				}
			/>
		</>
	);
};

export default Form;
