import { useState, useEffect, FC } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { CheckInput, Form as RHForm, TextInput, DateInput, FormInput } from '@/components';
import { useModal } from '@/hooks';
import ModalMessage from '@/components/Modal/modalMessage';
import useConfirmData from './hooks/useConfirmData';
import { t } from 'i18next';
import { ConfirmData } from '@/types/ConfirmData';
import { Errors } from '@/types';
import { ConfirmacaoTokenTypeEnum } from '@/config/enum';
import FileUploaderInput from '@/components/Form/FileUploaderInput';

interface Props {
	confirmData?: (data?: any) => void;
	defaultValues?: ConfirmData;
	token: string;
	tokenType: string;
	client_id: number;
	terms_link_url: string;
}

const FormConfirm: FC<Props> = ({ confirmData = () => {}, token, tokenType, client_id, defaultValues, terms_link_url }) => {
	const { isOpen, className, openModalWithClass, closeModal } = useModal();
	const [hasErrors, setHasErrors] = useState<boolean>(false);
	const {
		errors,
		setErrors,
		confirmSchema,
		waitingApi,
		employeeAlreadyConfirmed,
		createSimpleEmployeeBill,
	} = useConfirmData();
	const [startDate, setStartDate] = useState<Date>(new Date('1990-01-01'));

	useEffect(() => {
		if (errors !== undefined && Object.keys(errors).length !== 0) {
			const invalidInputs = document.querySelectorAll('.is-invalid');
			if (invalidInputs.length > 0) {
				invalidInputs[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
			setTimeout(() => {
				setHasErrors(true);
				openModalWithClass('danger');
			}, 1000);
		} else {
			setHasErrors(false);
		}
	}, [errors]);

	useEffect(() => {
		if (employeeAlreadyConfirmed) {
			let dataWithuseTerms: ConfirmData | { terms_of_use?: boolean } = defaultValues
				? defaultValues
				: {};
			dataWithuseTerms.terms_of_use = true;
			confirmData(dataWithuseTerms);
		}
	}, [employeeAlreadyConfirmed, defaultValues]);

	const handleChangeDate = (date: Date) => {
		setStartDate(date);
	};

	const handleFieldChange = (fieldName: keyof Errors) => {
		if (errors && errors[fieldName]) {
			const newApiErrors = { ...errors };
			delete newApiErrors[fieldName];
			setErrors(newApiErrors);
		}
	};

	return (
		<>
			<RHForm
				onSubmit={async (e) => {
					if (e) {
						if (e.terms_of_use == true) {
							// AQUI ESTA O PROCESSO ANTIGO, ONDE O USUARIO ERA DIRECIONADO PARA PREENCHER UM FORMULARIO GIGANTE
							// let dataWithuseTerms: ConfirmData | { terms_of_use?: boolean } =
							// 	defaultValues ? defaultValues : {};
							// dataWithuseTerms = e;
							// confirmData(dataWithuseTerms);

							// AGORA FAZEMOS ESSA OUTRA CHAMADA, QUE ENVIA OS DADOS DO USER E DA CONTA DE FORMA SIMPLIFICADA
							const data = await createSimpleEmployeeBill({
								name: e.name,
								telefone: e.telefone,
								birthdate: e.birthdate,
								file_document: e.file_document,
								client: client_id,
								token: tokenType === ConfirmacaoTokenTypeEnum.User ? token : null,
							});
							if (data?.id) {
								openModalWithClass('success');
							}
						}
					}
				}}
				schema={confirmSchema}
				defaultValues={defaultValues}
			>
				<div className="inputs">
					<Row>
						<Col xs={12} xl={6}>
							<TextInput
								type="text"
								name="name"
								label="Titular da Conta de Energia"
								placeholder="João da Silva"
								containerClass={'mb-2'}
							/>
						</Col>
						<Col xs={12} sm={6} xl={3}>
							<FormInput
								name="telefone"
								label="Telefone (Whatsapp)"
								placeholder="(99)99999-9999"
								type="phone_number"
								containerClass={'mb-2'}
								errors={errors}
								onChangeCustom={() => handleFieldChange('cpf')}
							/>
						</Col>
						<Col xs={12} sm={6} xl={3}>
							<DateInput
								label="Data de Nascimento"
								name="birthdate"
								className="form-control"
								value={startDate}
								onChange={(date) => handleChangeDate(date)}
								containerClass={'mb-2'}
								errors={errors}
								type={'date'}
							/>
						</Col>
						<Col lg={6}>
							<FileUploaderInput
								name="file_document"
								type="file"
								containerClass="mb-3 mt-0"
								label="Upload da Última Conta de Energia"
								helpText="Enviar PDF ou Imagem de até 20 MB."
								maxSize={20}
								maxFiles={1}
								errors={errors}
							/>
						</Col>
					</Row>
					{errors && <h4 className="red text-center">{errors['error']}</h4>}
				</div>

				<div className="checkbox_btn">
					<CheckInput
						name="terms_of_use"
						type="checkbox"
						containerClass="mb-2"
						label={
							<>
								Concordo com os&nbsp;
								<span className="text-muted cursor-pointer">
									<a
										href={terms_link_url || '#'}
										target="_blank"
										rel="noopener noreferrer"
									>
										termos do benefício
									</a>
								</span> 
								&nbsp;e estou ciente das&nbsp;
								<a
									href="https://www.kiiry.com/duvidas"
									target="_blank"
									rel="noopener noreferrer"
								>
									perguntas frequentes
								</a>
								.
							</>
						}
						defaultChecked={defaultValues?.terms_of_use ?? false}
					/>
					<Button
						id="btnSubmit"
						type="submit"
						variant="btnColor"
						disabled={hasErrors || waitingApi}
					>
						{waitingApi ? (
							<Spinner className="text-primary m-2" color="primary" size={'sm'} />
						) : (
							<>Solicitar Benefício</>
						)}
					</Button>
				</div>
			</RHForm>
			<ModalMessage
				isOpen={isOpen}
				toggleModal={
					className == 'success'
						? () => (window.location.href = 'https://www.kiiry.com/')
						: () => {
								closeModal();
								setErrors({});
							}
				}
				className={className}
				title={
					className == 'success'
						? t('pages.cliente.gestaoColaboradores.form.modal.success')
						: t('pages.cliente.gestaoColaboradores.form.modal.error')
				}
				errors={errors}
				customButtons={
					className == 'success' && (
						<>
							<Button variant={'btnColor'} onClick={() => window.location.reload()}>
								Solicitar Para Outro Endereço
							</Button>
							<Button
								variant={'btnColor'}
								onClick={() => (window.location.href = 'https://www.kiiry.com/')}
							>
								Ir para a página da KIIRY
							</Button>
						</>
					)
				}
			/>
		</>
	);
};

export default FormConfirm;
